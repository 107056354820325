<template>

  <div>
    <div
      id="to_top"
      class="fixed_circle active"
      :class="{
        unactive: $route.name.indexOf('Cart') >= 0 ||
        $route.name.indexOf('Login') >= 0 ||
        $route.name.indexOf('Register') >= 0 ||
        $route.name.indexOf('ForgetPassword') >= 0 ||
        $route.name.indexOf('Member') >= 0
      }">
        <img
          src="@/assets/img/icon/top_wave.svg"
          alt=""
          @click="scrollToTop">
    </div>
    <!-- <div id="fb-root" class="fixed_circle active"></div> -->

    <footer>
      <div class="inner w1300">
        <a href="" class="logo">
          <img src="@/assets/img/logo.png" alt="">
        </a>
        <nav class="nav">
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">Wi-Ho!產品</h6>
            <a href="" class="sub_link">
              <p>WiFi分享器</p>
            </a>
            <a href="" class="sub_link">
              <p>SIM卡</p>
            </a>
            <a href="" class="sub_link">
              <p>eSIM</p>
            </a>
            <a href="" class="sub_link">
              <p>旅遊周邊</p>
            </a>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">關於Wi-Ho!</h6>
            <a href="" class="sub_link">
              <p>公司介紹</p>
            </a>
            <a href="" class="sub_link">
              <p>公司沿革</p>
            </a>
          </div>
          <div class="footer_nav_item">
              <h6 class="sub_title txt-bold">最新活動</h6>
              <li>
                <a href="" class="">活動資訊</a>
              </li>
              <li>
                <a href="" class="">最新消息</a>
              </li>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">機場櫃台</h6>
            <a href="" class="sub_link">
              <p>桃園國際機場</p>
            </a>
            <a href="" class="sub_link">
              <p>台北松山機場</p>
            </a>
            <a href="" class="sub_link">
              <p>高雄小港機場</p>
            </a>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">企業專區</h6>
            <a href="" class="sub_link">
              <p>企業服務</p>
            </a>
            <a
              onclick="startMainLightbox('company_lightbox', false, true);"
              class="sub_link">
              <p>洽詢商務合作</p>
            </a>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">客服中心</h6>
            <a href="" class="sub_link">
              常見問題
            </a>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">生活講堂</h6>
            <a href="" class="sub_link">
              <p>Wi-Ho! Blog</p>
            </a>
            <a
              onclick="startMainLightbox('coop_lightbox', false);"
              class="sub_link">
              <p>異業合作</p>
            </a>
          </div>
        </nav>
      </div>
      <div class="footer_more w1300">
        <small class="more_set">
          <router-link to="/Terms/Copyright">著作權聲明</router-link>&nbsp;|&nbsp;
          <router-link to="/Terms/Privacy">隱私權聲明</router-link>&nbsp;|&nbsp;
          <router-link to="/Terms/Rent">租賃條款</router-link>&nbsp;|&nbsp;
          <router-link to="/Terms/TurnBack">退貨說明</router-link>&nbsp;|&nbsp;
          <a href="https://www.104.com.tw/company/1a2x6bint0?jobsource=2018indexpoc">人才招募</a>&nbsp;|
          <a href="https://line.me/R/ti/p/@wiho" target="_blank">
            <img src="@/assets/img/icon/navbar_line.svg" alt="">
          </a>
          <a href="https://www.facebook.com/wihotw"  target="_blank">
            <img src="@/assets/img/icon/navbar_fb.svg" alt="">
          </a>
        </small>
      </div>
    </footer>

    <div id="copyright">
      <div class="inner w1300 txt-white">
        <small>
          <img src="@/assets/img/index/icon_phone.svg" alt="">
          02-2545-7777
          <img src="@/assets/img/index/icon_mail.svg" alt="" style="margin-left:10px;">
          service@tswiho.com
        </small>
        <small>
          Copyright © Telecom Square Taiwan, Inc. All right reserved.
        </small>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
