export default {
  /**
   * @name 系統參數
   */
  baseUrl: 'member',
  login: {
    url: '/login',
    method: 'post',
    data: {
      account: true,
      password: true,
    },
    token: false,
  },
  register: {
    url: '/register',
    method: 'post',
    data: {
      email: true,
      password: true,
      mobile: true,
    },
    token: false,
  },
  profile: {
    url: '/profile',
    token: true,
  },
  updateProfile: {
    url: '/updateProfile',
    method: 'post',
    data: {
      name: true,
      sex: true,
      // email: true,
      zipcode: true,
      country_code: true,
      mobile: true,
      county: true,
      district: true,
      street: true,
      birthday: true,
      range: true,
      alley: true,
      lane: true,
      number: true,
      number_1: true,
      floor: true,
      floor_1: true,
      room: true,
    },
    token: true,
  },
  resetPassword: {
    url: '/resetPassword',
    method: 'post',
    data: {
      old_pwd: true,
      new_pwd: true,
    },
    token: true,
  },
  getNotifies: {
    url: '/getNotifies',
    token: true,
  },
  getUnreadMsgCount: {
    url: '/getUnreadMsgCount',
    token: true,
  },
  getOrders: {
    url: '/getOrders',
    token: true,
  },
  getOrderInfo: {
    url: '/getOrderInfo',
    token: true,
  },
  changeMsgStatus: {
    url: '/changeMsgStatus',
    data: {
      id: true,
    },
    token: true,
  },
  getQuestionnaire: {
    url: '/getQuestionnaire',
    data: {
      id: true,
    },
    token: true,
  },
  getCoupons: {
    url: '/getCoupons',
    data: {
      member_id: true,
      type: true,
    },
    token: true,
  },
  recordQuestionnaire: {
    url: '/recordQuestionnaire',
    method: 'post',
    data: {
      questionnaires: [],
    },
    token: true,
  },
  addFavorite: {
    url: '/addFavorite',
    method: 'post',
    data: {
      member_id: true,
      product_id: true,
    },
    token: true,
  },
  getTrackingList: {
    url: '/getTrackingList',
    token: true,
  },
  forgetPassowrd: {
    url: '/forgetPassowrd',
    method: 'post',
    data: {
      account: true,
    },
    token: false,
  },
};
