<template>
  <div id="News">
    <Header/>
    <div
      id="service_top"
      :style="`background:url(${require('@/assets/img/news/banner.jpg')});`">
        <div class="inner w1300 txt-white">
          <div class="title txt-center sr_right">
            <p class="txt-white txt-style-i">
              News
              <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="" style="width: .65em;">
            </p>
            <h2 class="h2-super_title txt-bold">
              最新消息 <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
            </h2>
          </div>
        </div>
    </div>

    <div id="step">
        <div class=" w1400 breadcrumbs">
          <span>
            <router-link to="/">首頁</router-link>
          </span> > 最新消息
        </div>
        <div class="inner w1400">
          <div class="bg">
              <div
                class="bg2"
                :style="`background-image: url(${require('@/assets/img/feedback/deco.png')})`">
              </div>
          </div>

          <ArticleTag
            :currentIndex="currentIndex"
            :categoryArray="categoryArray"/>

          <ArticleItemsLine
            :itemsArray="itemsArray"/>

          <!-- <Paginate/> -->
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ArticleTag from '@/components/article/ArticleTag.vue';
import ArticleItemsLine from '@/components/article/ArticleItemsLine.vue';
// import Paginate from '@/components/Paginate.vue';

import requestApi from '@/lib/http/index';

import '@/assets/scss/service.scss';

export default {
  name: 'Events',
  props: ['category', 'page'],
  components: {
    Header,
    ArticleTag,
    ArticleItemsLine,
    // Paginate,
  },
  data() {
    return {
      categoryArray: [],
      currentIndex: 0,
      itemsArray: [],
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        autoplay: true,
        delay: 5000,
        speed: 2000,
        pagination: {
          el: '#blog_top .blog_box .swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  methods: {
    async getData() {
      const result = await requestApi('newsApi.index');

      console.log(result);
      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            linkId: `/NewsInfo/${element.id}`,
            date: element.publish_day,
            category: element.category,
            title: element.title,
          });
        });
        this.itemsArray = arr;
      } else {
        this.itemsArray = [];
        // this.$customSWAL({
        //   icon: 'error',
        //   title: '錯誤',
        //   text: '無法取得資料',
        //   confirmButtonText: '確定',
        // });
      }
    },
    async getDataByCategory(categoryId) {
      const category = this.getCategoryName(categoryId);
      const result = await requestApi('newsApi.index', { category });
      this.itemsArray = [];
      console.log(result);
      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            linkId: `/NewsInfo/${element.id}`,
            date: element.publish_day,
            category: element.category,
            title: element.title,
          });
        });
        this.itemsArray = arr;
      } else {
        this.itemsArray = [];
      }
    },
    async getCategory() {
      const result = await requestApi('newsApi.getCategories');

      console.log(result);
      if (result.status) {
        const arr = [];
        arr.push({
          id: null,
          name: '全部資訊',
          link: '/News?category=&page=1',
        });
        result.data.forEach((element) => {
          arr.push({
            id: element.id,
            name: element.name,
            link: `/News?category=${element.id}&page=1`,
          });
        });
        this.categoryArray = arr;
      } else {
        this.categoryArray = [];
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '無法取得分類',
          confirmButtonText: '確定',
        });
      }
    },
    getCategoryName(id) {
      let name;
      this.categoryArray.forEach((element) => {
        if (id === element.id) {
          name = element.name;
        }
      });
      return name;
    },
    changeCategoryIndex(category) {
      this.categoryArray.forEach((item, i) => {
        if (item.id === category) {
          this.currentIndex = i;
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
      if (to !== from) {
        if (to.query.category) {
          this.getDataByCategory(to.query.category);
          this.changeCategoryIndex(to.query.category);
        } else {
          this.getData();
          this.changeCategoryIndex(null);
        }
      }
    },
  },
  mounted() {
    const getCategory = Promise.resolve(this.getCategory());
    getCategory.then(() => {
      if (this.$route.query.category) {
        this.getDataByCategory(this.$route.query.category);
        this.changeCategoryIndex(this.$route.query.category);
      } else {
        this.getData();
      }
    });
  },
};
</script>
