<template>
  <div>

    <div
      id="company_lightbox"
      :class="{active: lightboxStatus.companyLightbox}"
      class="main_lightbox">
      <div
        class="cover"
        @click="companyLightboxStatusChange(false)">
      </div>
      <div class="box">
        <img
          class="close"
          src="@/assets/img/icon/icon_X-w.svg"
          @click="companyLightboxStatusChange(false)"
          alt="">
        <div class="info">
          <div class="bg"></div>
          <div class="info_in">
            <h6 class="txt-blue">歡迎與我們聯絡諮詢 <img src="@/assets/img/icon/icon_wave_b.svg" alt=""></h6>
            <h2 class="txt-bold">為您的企業規劃最佳方案</h2>
            <form method="post" action="" class="form_box">
              <div class="form_item col100">
                <div class="radiobox">
                  <p>服務內容</p>
                  <!-- 會有許多個 -->
                  <input
                    type="radio"
                    name="service_item_id"
                    id="d1"
                    value="1"
                    v-model="companyLightboxData.service_type">
                  <label for="d1" class="p"><span></span>企業合作</label>
                  <input
                    type="radio"
                    name="service_item_id"
                    id="d2"
                    value="2"
                    v-model="companyLightboxData.service_type">
                  <label for="d2" class="p"><span></span>銀行</label>
                  <input
                    type="radio"
                    name="service_item_id"
                    id="d3"
                    value="3"
                    v-model="companyLightboxData.service_type">
                  <label for="d3" class="p"><span></span>旅行社</label>
                  <input
                    type="radio"
                    name="service_item_id"
                    id="d4"
                    value="4"
                    v-model="companyLightboxData.service_type">
                  <label for="d4" class="p"><span></span>代理商夥伴</label>
                  <input
                    type="radio"
                    name="service_item_id"
                    id="d5"
                    value="5"
                    v-model="companyLightboxData.service_type">
                  <label for="d5" class="p"><span></span>其他</label>
                </div>
              </div>
              <div class="form_item col50">
                <input
                  type="text"
                  placeholder="聯絡人"
                  v-model="companyLightboxData.name"
                  required>
              </div>
              <div class="form_item col50">
                <input
                  type="text"
                  placeholder="公司名稱"
                  v-model="companyLightboxData.company_name"
                  required>
              </div>
              <div class="form_item col50">
                <input
                  type="text"
                  placeholder="聯絡手機"
                  v-model="companyLightboxData.mobile"
                  required>
              </div>
              <div class="form_item col50">
                <input
                  type="email"
                  placeholder="Email"
                  v-model="companyLightboxData.email"
                  required>
              </div>
              <div class="form_item col100">
                <textarea
                  id="content"
                  cols="20" rows="5"
                  placeholder="洽詢合作內容"
                  v-model="companyLightboxData.content"
                  required>
                </textarea>
              </div>
              <div class="form_item col100 txt-right">
                <button
                @click="submitcompanyLightboxData(companyLightboxData)"
                type="button" class="btn btn-orange_white_line">
                  <img src="@/assets/img/icon/icon_next-o.svg" alt="">
                  <p>送出</p>
                </button>
              </div>
            </form>
          </div>
        </div>
        <swiper
          :options="lightboxSwiperData"
          class="deco_bg swiper-container">
          <swiper-slide>
            <figure
              class="swiper-slide"
              :style="`background-image: url(${require('@/assets/img/pop-up_2.jpg')});`">
            </figure>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div
      id="coop_lightbox"
      :class="{active: lightboxStatus.coopLightbox}"
      class="main_lightbox">
        <div
          class="cover"
          @click="coopLightboxStatusChange(false)">
        </div>
        <div class="box">
          <img
            class="close"
            src="@/assets/img/icon/icon_X-w.svg"
            @click="coopLightboxStatusChange(false)">
          <div class="info">
            <div class="bg"></div>
            <div class="info_in">
              <h2 class="txt-bold">誠摯歡迎各界跨領域合作</h2>
              <form class="form_box">
                <div class="form_item col50">
                  <input
                    type="text"
                    placeholder="聯絡人"
                    v-model="coopLightboxData.name"
                    required>
                </div>
                <div class="form_item col50">
                  <input
                    type="text"
                    placeholder="聯絡電話"
                    v-model="coopLightboxData.mobile"
                    required>
                </div>
                <div class="form_item col50">
                  <input
                    type="email"
                    placeholder="Email"
                    v-model="coopLightboxData.email"
                    required>
                </div>
                <div class="form_item col50">
                  <input
                    type="text"
                    placeholder="公司名稱"
                    v-model="coopLightboxData.company_name"
                    required>
                </div>
                <div class="form_item col100">
                  <input
                    type="text"
                    placeholder="公司網址"
                    v-model="coopLightboxData.company_url">
                </div>
                <div class="form_item col100">
                  <textarea
                    name="content"
                    id="content"
                    cols="20" rows="5"
                    placeholder="提案合作內容"
                    v-model="coopLightboxData.content"
                    required>
                  </textarea>
                </div>
                <div class="form_item col100 txt-right">
                  <button type="button" class="btn btn-orange_white_line"
                  @click="submitCoopLightboxData(coopLightboxData)">
                    <img src="@/assets/img/icon/icon_next-o.svg" alt="">
                    <p>送出</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="deco_bg swiper-container">
            <div class="swiper-wrapper">
              <figure
                class="swiper-slide"
                :style="`background-image: url(${require('@/assets/img/pop-up_blog.jpg')});`">
              </figure>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import requestApi from '@/lib/http/index';
import { required, checkMail } from '@/consts/validator';

export default {
  name: 'Lightboxs',
  data() {
    return {
      serviceType: [
        { name: '企業合作', value: 1 },
        { name: '銀行', value: 2 },
        { name: '旅行社', value: 3 },
        { name: '代理商夥伴', value: 4 },
        { name: '其它', value: 5 },
      ],
      companyLightboxData: {
        service_type: '企業合作',
        name: '',
        company_name: '',
        mobile: '',
        email: '',
        content: '',
      },
      coopLightboxData: {
        name: '',
        mobile: '',
        email: '',
        company_name: '',
        company_url: '',
        content: '',
      },
      lightboxSwiperData: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: false,
        effect: 'fade',
      },
      CompanyLightboxDataError: {
        service_type: '資料錯誤',
        name: '請填寫聯絡人',
        company_name: '請填寫公司名稱',
        mobile: '請填寫聯絡電話',
        email: '請填寫Email',
        content: '請填寫洽詢合作內容',
      },
      CoopLightboxDataError: {
        name: '請填寫聯絡人',
        mobile: '請填寫聯絡電話',
        email: '請填寫Email',
        company_name: '請填寫公司名稱',
        company_url: '請填寫公司網址',
        content: '請填寫提案合作內容',
      },
    };
  },
  computed: {
    ...mapState([
      'lightboxStatus',
    ]),
  },
  methods: {
    ...mapMutations([
      'coopLightboxStatusChange',
      'companyLightboxStatusChange',
    ]),
    success(v) {
      this.$customSWAL({
        icon: 'success',
        title: 'Success',
        text: v,
        confirmButtonText: '確定',
      });
    },
    error(v) {
      this.$customSWAL({
        icon: 'error',
        title: '錯誤',
        text: v,
        confirmButtonText: '確定',
      });
    },
    checkFormData(formData, errorArray) {
      let result = true;
      Object.keys(formData).every((key) => {
        console.log(key, formData[key]);
        if (!required(formData[key])) {
          this.error(errorArray[key]);
          result = false;
          return false;
          // eslint-disable-next-line
        } else if (key === 'email') {
          const validateResult = checkMail(formData[key]);
          console.log(validateResult);
          if (!validateResult) {
            this.error('請輸入正確的Email');
            result = false;
            return false;
          }
          return true;
        }
        return true;
      });
      if (result) {
        return true;
      }
      return false;
    },
    async submitcompanyLightboxData(formData) {
      console.log(formData);
      const checkFormData = await this.checkFormData(formData, this.CompanyLightboxDataError);
      this.serviceType.forEach((item) => {
        if (item.name === formData.service_type) {
          // eslint-disable-next-line
          formData.service_type = item.value;
        }
      });
      if (checkFormData) {
        const result = await requestApi('companyConsults.store', formData);
        console.log(result);
        if (result.status) {
          this.success('表單送出成功');
          this.reset('companyLightboxData');
          this.companyLightboxStatusChange(false);
        } else {
          this.error(result.message);
        }
      }
    },
    async submitCoopLightboxData(formData) {
      console.log(formData);
      const checkFormData = await this.checkFormData(formData, this.CoopLightboxDataError);
      if (checkFormData) {
        const result = await requestApi('cooperates.store', formData);
        console.log(result);
        if (result.status) {
          this.success('表單送出成功');
          this.reset('coopLightboxData');
          this.coopLightboxStatusChange(false);
        } else {
          this.error(result.message);
        }
      }
    },
    reset(formName) {
      if (formName === 'coopLightboxData') {
        Object.keys(this.coopLightboxData).forEach((key) => {
          this.coopLightboxData[key] = '';
        });
      } else if (formName === 'companyLightboxData') {
        Object.keys(this.companyLightboxData).forEach((key) => {
          if (key === 'service_type') {
            this.companyLightboxData[key] = '企業合作';
          } else {
            this.companyLightboxData[key] = '';
          }
        });
      }
    },
  },
};
</script>
