<template>
  <div>
    <Header/>
    <section id="bolg_info">
      <div class="inner w1300">
        <div class="breadcrumbs">
            <span class="txt-lightgrey">
              <router-link to="/">首頁</router-link> >&nbsp;
              <router-link :to="`/CompanyInfo?category=${category}&page=1`">
                {{category}}
              </router-link>
            </span> > {{title}}
        </div>

        <ArticleInfo
          :item="item"
        />
      </div>
    </section>

    <ArticleMoreItemsLine :itemsArray="itemsArray" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ArticleInfo from '@/components/article/ArticleInfo.vue';
import ArticleMoreItemsLine from '@/components/article/ArticleMoreItemsLine.vue';

import requestApi from '@/lib/http/index';

import '@/assets/scss/blog_info.scss';

export default {
  name: 'CompanyInfo',
  props: ['id'],
  components: {
    Header,
    ArticleInfo,
    ArticleMoreItemsLine,
  },
  data() {
    return {
      title: '',
      category: '',
      item: {
        main: {
          title: '',
          category: '',
          publishDay: '',
        },
        content: '',
      },
      itemsArray: [],
    };
  },
  methods: {
    async getData(id) {
      const result = await requestApi('companyArticles.info', { id });

      console.log(result);
      if (result.status) {
        const {
        // eslint-disable-next-line
        title, type, publish_day, content, banner,
        } = result.data;
        this.title = title;
        this.category = type;
        this.item = {
          main: {
            banner,
            title,
            category: type,
            publishDay: publish_day,
          },
          content,
        };
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '無法取得資料',
          confirmButtonText: '確定',
          thenFunc: () => {
            this.$router.back();
          },
        });
      }
    },
    async getRelationship(id) {
      const result = await requestApi('companyArticles.getRelationship', { id });

      console.log(result);
      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            linkId: `/CompanyInfo/${element.id}`,
            date: element.publish_day,
            category: element.category,
            title: element.title,
          });
        });
        this.itemsArray = arr;
      } else {
        this.itemsArray = [];
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
      if (to !== from) {
        this.getData(this.$route.params.id);
        this.getRelationship(this.$route.params.id);
      }
    },
  },
  mounted() {
    this.getData(this.$route.params.id);
    this.getRelationship(this.$route.params.id);
  },
};
</script>
