<template>
  <div class="product_info" v-if="product">
    <Header/>

    <section id="product_info" class="product_wifi">
      <div class="fix_bg"></div>
      <div class="inner w1300">
        <div class="breadcrumbs txt-white">
          <router-link :to="{ name: 'Home' }">
            首頁
          </router-link>
          &nbsp; > &nbsp;
          <router-link :to="this.getBreadcrumbRoute">
            {{product.category}}
          </router-link>
          &nbsp; > &nbsp;
          {{product.product_name}}
        </div>
        <div class="product_info_outer">
          <div class="w1000">
            <div id="product_buy">
              <div class="prouuct_pic">

                <!-- 旅遊周邊會有輪播 -->
                <template
                  v-if="product.parent === '旅遊周邊' && product.images.length > 0"
                >
                  <div
                    class="vertical_pic_next"
                    style="user-select: none;">
                      <img src="@/assets/img/icon/icon_down-b.svg" alt="">
                  </div>
                  <swiper
                    :options="swiperImgOption"
                    class="vertical_pic_set swiper-container"
                  >
                    <swiper-slide
                      @click.native="methodChangeProductPreviewImg(product.cover)"
                      class="swiper-wrapper">
                      <figure
                          class="vpic_change"
                          data-pic=""
                          :style="`background-image: url(${product.cover});`">
                        </figure>
                    </swiper-slide>
                    <swiper-slide
                      v-for="(colorImg, i) in product.images"
                      @click.native="methodChangeProductPreviewImg(colorImg.img)"
                      :key="`color_side_${i}`"
                      class="swiper-wrapper"
                    >
                      <figure
                        class="vpic_change"
                        data-pic=""
                        :style="`background-image: url(${colorImg.img});`">
                      </figure>
                    </swiper-slide>
                  </swiper>
                  <figure
                    class="main_pic"
                    :style="`background-image: url(${productPreviewImgUrl});`"
                  ></figure>
                </template>

                <figure
                  v-if="product.parent === '旅遊周邊' && product.images.length == 0"
                  class="main_pic" :style="`background-image: url(${product.cover});`">
                </figure>

                <figure
                  v-if="product.parent === '上網工具'"
                  class="main_pic" :style="`background-image: url(${product.cover});`">
                </figure>
              </div>

              <div class="product_text">
                <small class="tag-type txt-grey">{{product.category}}</small><br>
                <h1 class="txt-bold h4">{{product.product_name}}</h1>
                <div
                v-for="(tag, i) in JSON.parse(product.special_tags)" :key="`tag_${i}`"
                class="tag-flow txt-white h6">{{tag}}</div>
                <!-- 促銷文字 -->
                <div class="discount_box" v-if="true === false">
                  <p class="txt-orange txt-bold">
                    輸入 happy100 可享單筆現折$100&nbsp;&nbsp;
                    <img
                      src="@/assets/img/icon/icon_exclamation-o.svg"
                      alt=""
                      style="width: .65em;">
                  </p>
                </div>

                <!-- <div class="tag-flow txt-white h6">總輸出2.4A</div> -->
                <div class="prize_box">
                  <p>
                    售價&nbsp;
                    <span class="big txt-highbold txt-style-i">
                      {{product.sell_price}}
                    </span>&nbsp;
                    / {{product.unit}}&nbsp;
                    <span class="txt-line-through" v-if="product.price">
                      原價 {{product.price}}
                    </span>
                  </p>
                  <div class="fi_box">
                    <a href="" target="_blank" rel="noopener" class="line">
                      <img src="@/assets/img/share-line.svg" alt="">
                    </a>
                    <a href="" target="_blank" rel="noopener" class="fb">
                      <img src="@/assets/img/share-FB.svg" alt="">
                    </a>
                    <img class="copy" src="@/assets/img/share-link.svg" alt="">
                    <svg
                      class="heart"
                      v-if="user.id"
                      :class="{active: ifFav}"
                      @click="updateFav();"
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.222"
                      height="25"
                      viewBox="0 0 27.222 25">
                        <g fill="none">
                          <!-- eslint-disable max-len -->
                          <path class="heart_in" d="M13.611,25C6.293,20.008,2.454,15.467.88,11.667-3.337,1.481,8.708-3.389,13.611,2.623c4.9-6.013,16.948-1.142,12.731,9.043C24.768,15.467,20.93,20.008,13.611,25Z" stroke="none"/>
                          <!-- eslint-disable max-len -->
                          <path d="M 13.61110687255859 23.17511749267578 C 19.47274398803711 19.04762649536133 23.38648223876953 14.88289451599121 24.95628547668457 11.09280490875244 C 26.33060646057129 7.773505210876465 25.60686683654785 5.562105178833008 24.75771713256836 4.291464805603027 C 23.60703659057617 2.569634914398193 21.51632690429688 1.500005006790161 19.30147552490234 1.500005006790161 C 17.47072601318359 1.500005006790161 15.86269664764404 2.235534906387329 14.77359676361084 3.571105003356934 L 13.61110687255859 4.996665000915527 L 12.44861602783203 3.571105003356934 C 11.35952663421631 2.235534906387329 9.751496315002441 1.500005006790161 7.920746326446533 1.500005006790161 C 5.706146240234375 1.500005006790161 3.615546464920044 2.569705009460449 2.464776515960693 4.291675090789795 C 1.615576386451721 5.562395095825195 0.8917464017868042 7.773825168609619 2.26586651802063 11.09263515472412 C 3.835737705230713 14.88288688659668 7.749468803405762 19.04762649536133 13.61110687255859 23.17511749267578 M 13.61110687255859 25.0000057220459 C 6.292506217956543 20.00831413269043 2.45418643951416 15.46722507476807 0.8800364136695862 11.66662502288818 C -2.075560569763184 4.528192520141602 2.957449436187744 4.473876913380082e-07 7.920747756958008 4.473876913380082e-07 C 10.03910732269287 4.473876913380082e-07 12.14447402954102 0.8246000409126282 13.61110687255859 2.62314510345459 C 15.07744312286377 0.8249670267105103 17.18354797363281 4.473876913380082e-07 19.30147361755371 4.473876913380082e-07 C 24.26519775390625 4.473876913380082e-07 29.29804801940918 4.527570724487305 26.34218597412109 11.66662502288818 C 24.76803588867188 15.46722507476807 20.92971611022949 20.00831413269043 13.61110687255859 25.0000057220459 Z" stroke="none" fill="#ccc"/>
                        </g>
                    </svg>
                  </div>
                </div>
                <form id="addCarForm">
                  <div class="buybox" style="flex-wrap: wrap">
                    <template v-if="product.parent === '上網工具'">
                      <p>出國時間</p>
                      <date-picker
                        v-model="date"
                        class="datePicker"
                        type="date"
                        range
                        :disabledDate="date => date <= new Date().setDate(new Date().getDate() + birdDay)"
                        placeholder="出國日 － 回國日">
                      </date-picker>
                    </template>

                    <!-- 旅遊周邊沒有時間區間, 改規格or顏色的select -->
                    <!--<div style="width: 100%; display: flex; align-items: center;">
                        <p>廠牌</p>
                        <select
                          id=""
                          data-productid=""
                          class="small">
                            <option value="">請選擇</option>
                        </select>
                        <p>機型</p>
                        <select id="" class="small">
                            <option value="">請選擇</option>
                        </select>
                    </div>-->
                    <template v-if="brandOption.length > 0">
                      <div
                      v-if="product.category === 'eSIM' && brandOption.length > 0"
                      style="width: 100%; display: flex; align-items: center;">
                        <p>機型</p>
                        <select
                          @input="selectBrand($event)"
                          v-model="brand" class="small">
                            <template v-for="(brand, i) in brandOption">
                              <option v-show="brand.model.length > 0" :key="`brand_${i}`" :value="brand.id">{{brand.name}}</option>
                            </template>
                        </select>
                      </div>
                      <div
                      v-if="product.category === 'eSIM'"
                      style="width: 100%; display: flex; align-items: center;">
                        <p>機種</p>
                        <select v-model="model" class="small">
                            <option v-for="(selectBrand, i) in brandOption[this.brandIndex].model" :key="`color_${i}`" :value="selectBrand.id">{{selectBrand.name}}</option>
                        </select>
                      </div>
                    </template>
                    <div
                    v-if="product.parent === '旅遊周邊' && product.colors.length > 0"
                    style="width: 100%; display: flex; align-items: center;">
                      <p>顏色</p>
                      <select v-model="select" class="small">
                        <option v-for="(color, i) in product.colors" :key="`color_${i}`" :value="color.id">{{color.color_name}}</option>
                      </select>
                    </div>
                    <!--  -->
                  </div>

                  <div class="btnset">
                    <div class="number" data-max="12">
                      <div class="minus" @click="count !== 1 ? count-- : 1">-</div>
                      <input
                        class="count"
                        id="count_"
                        type="text"
                        v-model="count"
                        readonly>
                      <div class="plus" @click="addCount()">+</div>
                    </div>
                    <!-- <a type="button" class="btn btn-yellow p" href="https://docs.google.com/forms/d/e/1FAIpQLSf9hObXXivKkPlkGxOZ6oHdAzBeYn1CxknzP7pbL9KurNBBDA/viewform" target="_blank">
                      <span>立即預約</span>
                      <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
                    </a> -->
                    <button type="button" class="btn btn-yellow p" @click="addCart(true)">
                      <span>立即購買</span>&nbsp;
                      <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
                    </button>
                    <button
                      type="button"
                      id=""
                      @click="addCart(false)"
                      class="btn btn-cyan_line p">
                        加入購物車
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div id="product_note">
              <div class="more_tagset">
                <div
                  class="tag txt-greenlake"
                  :class="{active : productNote == 'point'}"
                  @click="changeProductNote('point')">
                    <p>商品特色</p>
                </div>
                <div
                  v-if="product.spec"
                  class="tag txt-greenlake"
                  :class="{active : productNote == 'spec'}"
                  @click="changeProductNote('spec')">
                    <p>商品規格</p>
                </div>
                <div
                  v-if="product.attention"
                  class="tag txt-greenlake"
                  :class="{active : productNote == 'note'}"
                  @click="changeProductNote('note')">
                    <p>注意事項</p>
                </div>
                <div
                  v-if="product.shipping_cost || product.payment_info || product.deadline_info || product.other"
                  class="tag txt-greenlake"
                  :class="{active : productNote == 'buy_notice'}"
                  @click="changeProductNote('buy_notice')">
                    <p>購買須知</p>
                </div>
              </div>
              <div class="more_infoset">
                <div
                  class="more_info point"
                  :class="{active : productNote == 'point'}">
                    <div class="features_box">
                      <p v-for="(item, i) in JSON.parse(product.features)" :key="`feature_${i}`">
                        <img src="@/assets/img/products/icon_v_o.svg" alt="">&nbsp;
                        {{item}}
                      </p>
                    </div>
                    <div
                      v-if="product.video_title"
                      class="features_box video_text" style="width:100%; margin:10px 0;">
                        <h6>{{product.video_title}}</h6>
                    </div>
                    <div
                      v-if="product.youtube_id"
                      class="features_box video_outer" style="width:100%; margin:0 0 10px;">
                        <iframe :src="`https://www.youtube.com/embed/${product.youtube_id}`" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                    <div
                      v-if="product.feature_intro"
                      class="features_box"
                      style="width:100%; margin:10px 0; white-space: pre-line;"
                      v-html="product.feature_intro"
                    >
                    </div>
                </div>
                <template v-if="product.spec">
                  <div
                    class="more_info spec"
                    :class="{active : productNote == 'spec'}">
                      <div class="spec_block p">
                        <table>
                          <tbody>
                            <tr
                              v-for="(item, i) in JSON.parse(product.spec)"
                              :key="`spec_${i}`">
                                <td class="item_name txt-grey">{{item[0].name}}</td>
                                <td class="item_note">{{item[0].content}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                  </div>
                </template>
                <div
                  class="more_info note"
                  :class="{active : productNote == 'note'}" v-if="product.attention">
                    <!-- 給編輯器用的容器 -->
                    <div
                      class="features_box"
                      style="white-space: pre-line;"
                      v-html="product.attention">
                    </div>
                </div>
                <!-- 影片導覽 -->
                <!-- <div
                  class="more_info video"
                  :class="{active : productNote == 'video'}">
                    <div class="video_text">
                      <h6>title</h6><br>
                      <p>description</p>
                    </div>
                    <div class="video_outer">
                      <iframe src="" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                </div> -->
                <div class="more_info buy_notice" :class="{active : productNote == 'buy_notice'}">
                  <div class="spec_block p">
                    <table>
                      <tbody>
                        <tr v-if="product.shipping_cost">
                          <td class="item_name txt-grey">商品運費</td>
                        </tr>
                        <template v-if="product.shipping_cost">
                          <tr
                            v-for="(cost, i) in JSON.parse(product.shipping_cost)"
                            :key="`cost_${i}`"
                          >
                            <td
                            class="item_note"
                            >
                              {{cost}}<br>
                            </td>
                          </tr>
                        </template>
                        <tr v-if="product.payment_info">
                          <td class="item_name txt-grey">付款資訊</td>
                        </tr>
                        <template v-if="product.payment_info">
                          <tr v-for="(info, i) in JSON.parse(product.payment_info)"
                            :key="`info_${i}`">
                            <td
                            class="item_note"
                            >
                              {{info}}<br>
                            </td>
                          </tr>
                        </template>
                        <tr v-if="product.deadline_info">
                          <td class="item_name txt-grey">預約截止日</td>
                        </tr>
                        <template v-if="product.deadline_info">
                          <tr v-for="(deadline, i) in JSON.parse(product.deadline_info)"
                          :key="`deadline_${i}`">
                            <td
                            class="item_note"
                            >
                              {{deadline}}<br>
                            </td>
                          </tr>
                        </template>
                        <tr v-if="product.other">
                            <td class="item_name txt-grey">其它</td>
                        </tr>
                        <template v-if="product.other">
                          <tr v-for="(other, i) in JSON.parse(product.other)"
                          :key="`other_${i}`">
                            <td
                            class="item_note"
                            >
                              {{other}}<br>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <template v-if="this.questionnaires && this.questionnaires.length > 0">
      <section id="choose_wiho">
        <div class="bg">
          <figure :style="`background-image: url(${require('@/assets/img/feedback/img.jpg')});`"></figure>
        </div>
        <div class="inner w1400">
          <h3 class="h2-super_title txt-bold txt-white txt-center">
            真實好評 <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
          </h3>
          <swiper
            v-if="this.questionnaires.length > 0"
            :options="swiperOption"
            class="go_abroad_box box1 swiper-container">
            <swiper-slide
                v-for="(item, i) in this.questionnaires" :key="`questionnare_${i}`">
              <div class="itembox_outer">
                <div class="itembox swiper-container">
                  <div class="swiper-wrapper">
                      <div class="item swiper-slide">
                        <div class="top">
                          <img src="@/assets/img/icon/icon_quotation_mark.svg" alt="">
                          <div class="star_box">
                            <p class="txt-lightblue">整體滿意度</p>
                            <div>
                              <img
                                v-for="i in Math.floor(item.star)"
                                :key="`star_${i}`"
                                src="@/assets/img/index/icon_star.svg" alt="">
                            </div>
                          </div>
                        </div>
                        <h6 class="text_show">{{item.reason}}</h6>
                        <div class="type">
                          <p>
                            <template v-if="item.name">
                              {{item.name.substr(0, 1)}}
                            </template>
                            <template v-if="item.sex === 'm'">
                              先生
                            </template>
                            <template v-if="item.sex === 'f'">
                              小姐
                            </template>
                          </p>
                          <p class="txt-lightblue">{{item.product}}</p>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapState } from 'vuex';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import Header from '@/components/Header.vue';
import requestApi from '@/lib/http/index';
import { getStorage } from '../utils/timerStorage';

import '@/assets/scss/product_info.scss';

export default {
  name: 'ProductInfo',
  props: ['id'],
  components: {
    Header,
    DatePicker,
  },
  data() {
    return {
      count: 1,
      ifFav: false,
      select: null,
      brand: null,
      brandIndex: 0,
      model: null,
      brandOption: [],
      date: [],
      productNote: 'point',
      product: null,
      questionnaires: null,
      productPreviewImgUrl: '',
      swiperImgOption: {
        loop: false,
        direction: 'vertical',
        allowTouchMove: true,
        slidesPerView: 3,
        navigation: {
          nextEl: '#product_info .vertical_pic_next',
        },
      },
      swiperOption: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 30,
        allowTouchMove: false,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        pagination: {
          el: '#choose_wiho .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1023: {
            slidesPerView: 2.5,
          },
          880: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 1.5,
          },
          425: {
            slidesPerView: 1,
          },
        },
      },
      birdDay: 0,
    };
  },
  computed: {
    ...mapState([
      'cart',
      'isLogin',
      'isMemberInfo',
      'user',
    ]),
    getBreadcrumbRoute() {
      let obj = {
        name: 'Product',
        params: {
          category: '1',
          productCategory: '1',
        },
      };
      if (this.product && this.product.category) {
        switch (this.product.category) {
          case 'WIFI分享器':
            obj = {
              name: 'Product',
              params: {
                category: '1',
              },
              query: {
                productCategory: '1',
              },
            };
            break;
          case 'SIM卡':
            obj = {
              name: 'Product',
              params: {
                category: '1',
              },
              query: {
                productCategory: '2',
              },
            };
            break;
          case 'eSIM':
            obj = {
              name: 'Product',
              params: {
                category: '1',
              },
              query: {
                productCategory: '3',
              },
            };
            break;
          default:
            obj = {
              name: 'Product',
              params: {
                category: '2',
              },
              query: {
                productCategory: this.product.category,
              },
            };
            break;
        }
      }
      return obj;
    },
  },
  methods: {
    ...mapMutations([
      'setCart',
    ]),
    async checkEnoughStock({
      qty = this.count,
    } = {}) {
      const [abroadStart, abroadEnd] = this.date;
      const { status, message } = await requestApi('cart.checkEnoughStock', {
        abroad_start: moment(abroadStart).format('YYYY-MM-DD'),
        abroad_end: moment(abroadEnd).format('YYYY-MM-DD'),
        product_id: this.product.id,
        qty,
        code: this.$route.query.code || null,
      });
      return { status, message };
    },
    getDays(abroadStart, abroadEnd) {
      const startDay = moment(abroadStart, 'YYYY-MM-DD');
      const endDay = moment(abroadEnd, 'YYYY-MM-DD');
      const days = endDay.diff(startDay, 'days') + 1;
      return days;
    },
    async getBrands() {
      const { status, data } = await requestApi('req.getBrands');
      if (status) {
        this.brandOption = data.map((item, i) => {
          if (i === 0) {
            this.brand = item.id;
          }
          const obj = {
            ...item, model: [],
          };
          this.getMobileModelByBrand(item.id, i);
          return obj;
        });
      }
    },
    async getMobileModelByBrand(id, i) {
      const { status, data } = await requestApi('req.getMobileModelByBrand', { brand_id: id });
      if (status) {
        if (data.length > 0) {
          this.model = data[0].id;
        }
        this.brandOption[i].model = data;
      }
    },
    async addCart(toCart = false) {
      if (this.isLogin) {
        if (this.isMemberInfo) {
          const { status, message } = await this.checkEnoughStock();
          if (status) {
            const formDataProcess = new Promise((resolve, reject) => {
              const formData = {
                member_id: this.user.id,
              };
              console.log(this.getBreadcrumbRoute.query.productCategory);
              if (this.product.parent === '上網工具') {
                if (this.date.length > 0) {
                  if (this.date[0] && this.date[1]) {
                    const [abroadStart, abroadEnd] = this.date;
                    console.log(abroadStart, abroadEnd);
                    formData.abroad_start = moment(abroadStart).format('YYYY/MM/DD');
                    formData.abroad_end = moment(abroadEnd).format('YYYY/MM/DD');
                    formData.days = this.getDays(abroadStart, abroadEnd);
                    if (this.product.type === '出租品') {
                      formData.total = this.product.sell_price * this.count * formData.days;
                    } else {
                      formData.total = this.product.sell_price * this.count;
                    }
                  } else {
                    reject(new Error('請填寫出國時間'));
                  }
                } else {
                  reject(new Error('請填寫出國時間'));
                }
              } else if (this.product.parent === '旅遊周邊') {
                if (this.select) {
                  formData.color_id = this.select;
                }
                formData.total = this.product.sell_price * this.count;
              }
              if (this.$route.query.code) {
                formData.code = this.$route.query.code;
              }
              if (this.product.category === 'eSIM') {
                formData.brand_id = this.brand;
                formData.mobile_model_id = this.model;
              }
              formData.product_id = this.product.id;
              formData.price = this.product.price;
              formData.sell_price = this.product.sell_price;
              formData.qty = this.count;
              resolve(formData);
            });
            formDataProcess.then((form) => {
              console.log(form);
              const addCart = requestApi('cart.addCart', form);
              addCart.then((result) => {
                console.log(result);
                if (result.status) {
                  // const { cart } = this;
                  // this.setCart(cart + 1);
                  if (toCart) {
                    this.$router.push({ name: 'CartInfo' });
                  } else {
                    this.$customSWAL({
                      icon: 'success',
                      title: 'Success',
                      text: '加入購物車成功',
                      confirmButtonText: '確定',
                      thenFunc: () => {
                        this.$router.go(0);
                      },
                    });
                  }
                } else {
                  this.$customSWAL({
                    icon: 'error',
                    title: '錯誤',
                    text: result.message,
                    confirmButtonText: '確定',
                  });
                }
              });
            }).catch((err) => {
              this.$customSWAL({
                icon: 'error',
                title: '錯誤',
                text: err.message,
                confirmButtonText: '確定',
              });
            });
          } else {
            this.$customSWAL({
              icon: 'info',
              title: message,
            });
          }
        } else {
          this.$customSWAL({
            icon: 'error',
            title: '錯誤',
            text: '請先填寫會員資料',
            confirmButtonText: '確定',
            thenFunc: () => {
              this.$router.push({
                name: 'MemberData',
              });
            },
          });
        }
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '請先登入帳號',
          confirmButtonText: '確定',
          thenFunc: () => {
            this.$router.push({ path: '/Login', query: { pathname: window.location.pathname, search: window.location.search } });
          },
        });
      }
    },
    async addCount() {
      const { status, message } = await this.checkEnoughStock({ qty: this.count + 1 });
      if (status) {
        this.count += 1;
      } else {
        this.$customSWAL({
          icon: 'info',
          title: message,
        });
      }
    },
    changeProductNote(noteName) {
      this.productNote = noteName;
    },
    selectBrand(e) {
      this.brand = e.target.value;
      this.brandOption.forEach((item, i) => {
        if (item.id === e.target.value) {
          this.brandIndex = i;
          if (item.model[0]) {
            this.model = item.model[0].id;
          }
        }
      });
    },
    async getData() {
      const { id } = this.$route.params;
      let api = '';
      if (getStorage('token')) {
        api = 'product.infoToken';
      } else {
        api = 'product.info';
      }
      const result = await requestApi(api, { id });
      if (result.status) {
        this.product = result.data;
        if (result.data.category === 'eSIM') {
          this.getBrands();
        }
        this.productPreviewImgUrl = this.product?.images[0]?.img ?? this.product?.cover ?? '';
        if (result.data.is_tracking) {
          this.ifFav = this.product.is_tracking;
        }
        if (parseInt(result.data.bird_day, 10) > 0) {
          this.birdDay = parseInt(result.data.bird_day, 10);
        } else {
          this.birdDay = -1;
        }
        console.log(this.birdDay);
      } else {
        // this.$router.push({ name: 'Product' });
      }
    },
    async getQuestionnaires() {
      const result = await requestApi('product.getQuestionnaires');
      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          if (element.product_id === this.$route.params.id) {
            arr.push(element);
          }
        });
        this.questionnaires = arr;
      } else {
        this.questionnaires = null;
        console.log(result.message);
      }
    },
    methodChangeProductPreviewImg(url = '') {
      this.productPreviewImgUrl = url;
    },
    async updateFav() {
      if (!this.user.id) {
        this.$customSWAL({
          icon: 'error',
          title: '請先登入',
        });
      } else {
        const { status, message } = await requestApi('member.addFavorite', {
          member_id: this.user.id,
          product_id: this.id,
        });

        if (status) {
          this.$customSWAL({
            title: message,
          });
        }
        console.log(status, message);
      }
      this.ifFav = !this.ifFav;
    },
  },
  mounted() {
    this.getData();
    this.getQuestionnaires();
    // this.ifFav = this.product.is_tracking;
  },
};
</script>
