import requestApi, { setToken } from '../lib/http';
import { getStorage, setStorage, clearStorage } from '../utils/timerStorage';

// 初始化
export const init = ({ dispatch }) => {
  const token = getStorage('token');
  if (token) {
    dispatch('handleLoginInfo', { token });
  }
};

// 註冊後 純粹為了註冊系列彈窗 設置 token
export const setRegisterToken = async ({ dispatch }, token) => {
  console.log('setRegisterToken', token, dispatch);
  if (token) {
    console.log('setRegisterToken token', token);
    // const expiredRange = 365 * 24 * 60 * 60 * 1000;
    const expiredRange = 90 * 24 * 60 * 60 * 1000;
    const expiredIn = Date.now() + expiredRange;
    setStorage('token', token, expiredIn);
    setToken(token);
  }
};

export const handleLoginInfo = async ({ commit, dispatch }, { token }) => {
  if (token) {
    // const expiredRange = 365 * 24 * 60 * 60 * 1000;
    const expiredRange = 90 * 24 * 60 * 60 * 1000;
    const expiredIn = Date.now() + expiredRange;
    setStorage('token', token, expiredIn);
    setToken(token);
    await dispatch('updateUserProfile');
    commit('setIsLogin', true);
    return true;
  }
  await dispatch('logout');
  return false;
};

export const logout = ({ commit }) => {
  commit('setIsLogin', false);
  clearStorage();
};

/* eslint-disable camelcase */
export const updateUserProfile = async ({ commit }) => {
  const { status, data } = await requestApi('member.profile');
  if (status) {
    const {
      id = null,
      account = null,
      name = null,
      sex = null,
      birthday = null,
      email = null,
      country_code = null,
      mobile = null,
      tel = null,
      tel_1 = null,
      county = null,
      district = null,
      street = null,
      address = null,
      zipcode = null,
      range = null,
      alley = null,
      lane = null,
      number = null,
      number_1 = null,
      floor = null,
      floor_1 = null,
      room = null,
      is_company = null,
      pay_way = null,
      start_day = null,
      end_day = null,
      active = null,
    } = data ?? {};
    const userInfo = {
      id,
      account,
      name,
      sex,
      birthday,
      email,
      country_code,
      mobile,
      tel,
      tel_1,
      county,
      district,
      street,
      address,
      zipcode,
      range,
      alley,
      lane,
      number,
      number_1,
      floor,
      floor_1,
      room,
      is_company,
      pay_way,
      start_day,
      end_day,
      active,
    };
    commit('setUserInfo', userInfo);
  }
};
