<template>
  <div>
    <Header/>

    <section id="blog_top">
      <swiper
        v-if="itemsArray.length > 0"
        :options="swiperOption"
        class="blog_box swiper-container">
        <swiper-slide v-for="(data, i) in itemsArray" :key="`banner_${i}`">
          <router-link
            :to="data.linkId"
            class="blog_item swiper-slide">
            <figure
                :style="`background-image: url(${data.banner});`">
            </figure>
          </router-link>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section id="blog">
      <div class="w1400 breadcrumbs">
        <span>
          <router-link to="/">首頁</router-link>
        </span> > 活動資訊
      </div>

      <div class="inner w1400">
        <div class="bg">
          <div
            class="bg2"
            :style="`background-image: url(${require('@/assets/img/feedback/deco.png')})`">
          </div>
        </div>

        <div class="title">
          <p class="txt-blue txt-style-i">
            Sale&nbsp;
            <img src="@/assets/img/icon/icon_exclamation.svg" alt="" style="width: .65em;">
          </p>
          <h2 class="h2-super_title txt-bold">
            活動資訊 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
          </h2>
        </div>

        <ArticleTag
          :currentIndex="currentIndex"
          :categoryArray="categoryArray"/>

        <ArticleItems
          :itemsArray="itemsArray"/>

        <!-- <Paginate/> -->
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ArticleTag from '@/components/article/ArticleTag.vue';
import ArticleItems from '@/components/article/ArticleItems.vue';
// import Paginate from '@/components/Paginate.vue';

import requestApi from '@/lib/http/index';

import '@/assets/scss/blog.scss';

export default {
  name: 'Events',
  props: ['category', 'page'],
  components: {
    Header,
    ArticleTag,
    ArticleItems,
    // Paginate,
  },
  data() {
    return {
      categoryArray: [],
      currentIndex: 0,
      itemsArray: [],
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        autoplay: true,
        delay: 5000,
        speed: 2000,
        pagination: {
          el: '#blog_top .blog_box .swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  methods: {
    async getData() {
      const result = await requestApi('event.index');

      console.log(result);
      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            linkId: `/EventInfo/${element.id}`,
            pic: element.cover,
            banner: element.banner,
            date: element.publish_day,
            category: element.category,
            title: element.title,
          });
        });
        this.itemsArray = arr;
      } else {
        this.itemsArray = [];
        // this.$customSWAL({
        //   icon: 'error',
        //   title: '錯誤',
        //   text: '無法取得資料',
        //   confirmButtonText: '確定',
        // });
      }
    },
    async getDataByCategory(categoryId) {
      const category = this.getCategoryName(categoryId);
      const result = await requestApi('event.index', { category });
      if (result.status) {
        this.itemsArray = [];
        console.log(result);
        const arr = [];
        result.data.forEach((element) => {
          arr.push({
            linkId: `/EventInfo/${element.id}`,
            banner: element.banner,
            pic: element.cover,
            date: element.publish_day,
            category: element.category,
            title: element.title,
          });
        });
        this.itemsArray = arr;
      } else {
        this.itemsArray = [];
      }
    },
    async getCategory() {
      const result = await requestApi('event.getCategories');

      console.log(result);
      if (result.status) {
        const arr = [];
        arr.push({
          id: null,
          name: '全部資訊',
          link: '/Events?category=&page=1',
        });
        result.data.forEach((element) => {
          arr.push({
            id: element.id,
            name: element.name,
            link: `/Events?category=${element.id}&page=1`,
          });
        });
        this.categoryArray = arr;
      } else {
        this.categoryArray = [];
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '無法取得分類',
          confirmButtonText: '確定',
        });
      }
    },
    getCategoryName(id) {
      let name;
      this.categoryArray.forEach((element) => {
        if (id === element.id) {
          name = element.name;
        }
      });
      return name;
    },
    changeCategoryIndex(category) {
      this.categoryArray.forEach((item, i) => {
        if (item.id === category) {
          this.currentIndex = i;
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
      if (to !== from) {
        if (to.query.category) {
          this.getDataByCategory(to.query.category);
          this.changeCategoryIndex(to.query.category);
        } else {
          this.getData();
          this.changeCategoryIndex(null);
        }
      }
    },
  },
  mounted() {
    const getCategory = Promise.resolve(this.getCategory());
    getCategory.then(() => {
      if (this.$route.query.category) {
        this.getDataByCategory(this.$route.query.category);
        this.changeCategoryIndex(this.$route.query.category);
      } else {
        this.getData();
      }
    });
  },
};
</script>
