<template>
  <div>
    <Header/>
    <ProductFilter
      :category="category"/>

    <section id="product">
      <ProductList
        v-for="(item, key) in productSetsData"
        :key="`list${key}`"
        :itemsArray="itemsArray"
        :category="category"
        :brandOption="brandOption"
        :productCategory="item.productCategory"
        :productDiscountTag="item.productDiscountTag"
        :productCategoryName="item.productCategoryName"
        :productCategoryTag="item.productCategoryTag"
        :productCategoryPoint="item.productCategoryPoint"/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import Header from '@/components/Header.vue';
import ProductFilter from '@/components/product/ProductFilter.vue';
import ProductList from '@/components/product/ProductList.vue';
import requestApi from '@/lib/http/index';
import { getStorage, setStorage } from '../utils/timerStorage';

import '@/assets/scss/product.scss';

export default {
  name: 'Product',
  props: ['category'],
  components: {
    Header,
    ProductFilter,
    ProductList,
  },
  data() {
    return {
      productSetsData: [
        // {
        //   productCategory: 1,
        //   productDiscountTag: '全館滿$499免運費',
        //   productCategoryName: '',
        //   productCategoryTag: ['團體/多裝置'],
        //   productCategoryPoint: ['多人分享 價格划算', '訊號穩定 網速快', '免換卡操作方便'],
        // },
      ],
      itemsArray: [],
      brandOption: [],
    };
  },
  methods: {
    getProductSetsData() {
      console.log('get', this.$route.query);
      this.productSetsData = [];
      if (Number(this.category) === 1) {
        if (Number(this.$route.query.productCategory) === 1) {
          this.productSetsData = [
            {
              productCategory: 1,
              productDiscountTag: '全館滿$499免運費',
              productCategoryName: 'WIFI分享器',
              productCategoryTag: ['團體/多裝置'],
              productCategoryPoint: ['多人分享 價格划算', '訊號穩定 網速快', '免換卡操作方便'],
            },
          ];
        } else if (Number(this.$route.query.productCategory) === 2) {
          this.productSetsData = [
            {
              productCategory: 2,
              productDiscountTag: '全館滿$499免運費',
              productCategoryName: 'SIM卡',
              productCategoryTag: ['1~2人'],
              productCategoryPoint: ['隨插即用', '不需額外攜帶裝置', '用完即丟 免歸還'],
            },
          ];
        } else if (Number(this.$route.query.productCategory) === 3) {
          this.productSetsData = [
            {
              productCategory: 3,
              productDiscountTag: '全館滿$499免運費',
              productCategoryName: 'eSIM',
              productCategoryTag: ['1~2人'],
              productCategoryPoint: ['無卡上網商務新選擇', '原門號可通話', '電子憑證取件', '掃描QR Code簡單方便'],
            },
          ];
        } else {
          this.productSetsData = [
            {
              productCategory: 1,
              productDiscountTag: '全館滿$499免運費',
              productCategoryName: '',
              productCategoryTag: ['團體/多裝置'],
              productCategoryPoint: ['多人分享 價格划算', '訊號穩定 網速快', '免換卡操作方便'],
            },
            {
              productCategory: 2,
              productDiscountTag: '全館滿$499免運費',
              productCategoryName: '',
              productCategoryTag: ['團體/多裝置'],
              productCategoryPoint: ['多人分享 價格划算', '訊號穩定 網速快', '免換卡操作方便'],
            },
            {
              productCategory: 3,
              productDiscountTag: '全館滿$499免運費',
              productCategoryName: '',
              productCategoryTag: ['團體/多裝置'],
              productCategoryPoint: ['多人分享 價格划算', '訊號穩定 網速快', '免換卡操作方便'],
            },
          ];
        }
      } else if (this.$route.query.productCategory !== '0') {
        this.productSetsData = [
          {
            productCategory: 6,
            productDiscountTag: '全館滿$499免運費',
            productCategoryName: this.getCategoryName,
            productCategoryTag: [],
            productCategoryPoint: [],
          },
        ];
      }
    },
    getCategoryType(data) {
      if (parseInt(this.$route.params.category, 10)) {
        const arr = [];
        data.forEach((item) => {
          if (arr.filter((e) => e.productCategoryName === item.category).length === 0) {
            if (item.category === 'WIFI分享器') {
              arr.push({
                productCategory: 1,
                productDiscountTag: '全館滿$499免運費',
                productCategoryName: item.category,
                productCategoryTag: ['團體/多裝置'],
                productCategoryPoint: ['多人分享 價格划算', '訊號穩定 網速快', '免換卡操作方便'],
              });
            } else if (item.category === 'SIM卡') {
              arr.push({
                productCategory: 2,
                productDiscountTag: '全館滿$499免運費',
                productCategoryName: item.category,
                productCategoryTag: ['1~2人'],
                productCategoryPoint: ['隨插即用', '不需額外攜帶裝置', '用完即丟 免歸還'],
              });
            } else if (item.category === 'eSIM') {
              arr.push({
                productCategory: 3,
                productDiscountTag: '全館滿$499免運費',
                productCategoryName: item.category,
                productCategoryTag: ['1~2人'],
                productCategoryPoint: ['無卡上網商務新選擇', '原門號可通話', '電子憑證取件', '掃描QR Code簡單方便'],
              });
            } else {
              arr.push({
                productCategory: 6,
                productDiscountTag: '全館滿$499免運費',
                productCategoryName: item.category,
                productCategoryTag: [],
                productCategoryPoint: [],
              });
            }
          }
        });
        this.productSetsData = arr;
        this.itemsArray = data;
      } else {
        const arr = [];
        data.forEach((item) => {
          if (arr.filter((e) => e.productCategoryName === item.category).length === 0) {
            arr.push({
              productCategory: 6,
              productDiscountTag: '全館滿$499免運費',
              productCategoryName: item.category,
              productCategoryTag: [],
              productCategoryPoint: [],
            });
          }
        });
        this.productSetsData = arr;
        this.itemsArray = data;
      }
    },
    async getBrands() {
      const { status, data } = await requestApi('req.getBrands');
      if (status) {
        this.brandOption = data.map((item, i) => {
          const obj = {
            ...item, model: [],
          };
          this.getMobileModelByBrand(item.id, i);
          return obj;
        });
      }
    },
    async getMobileModelByBrand(id, i) {
      const { status, data } = await requestApi('req.getMobileModelByBrand', { brand_id: id });
      if (status) {
        this.brandOption[i].model = data;
      }
    },
    async getData() {
      const getFormData = new Promise((resolve) => {
        // eslint-disable-next-line
        const { productCategory, abroad_start, abroad_end, flowType, countries, sort } = this.$route.query;
        const formData = {};
        console.log(parseInt(productCategory, 10) === 0 && this.$route.params.category === 2);
        if (parseInt(productCategory, 10) === 1) {
          formData.category = 'WIFI分享器';
        } else if (parseInt(productCategory, 10) === 2) {
          formData.category = 'SIM卡';
        } else if (parseInt(productCategory, 10) === 3) {
          formData.category = 'eSIM';
          this.getBrands();
        } else if (parseInt(this.$route.query.productCategory, 10) === 0
        && parseInt(this.$route.params.category, 10) === 1) {
          formData.category = '上網工具';
        } else if (parseInt(this.$route.query.productCategory, 10) === 0
        && parseInt(this.$route.params.category, 10) === 2) {
          formData.category = '旅遊周邊';
        } else {
          formData.category = productCategory;
        }
        // eslint-disable-next-line
        if (abroad_start && abroad_end) {
        // eslint-disable-next-line
          formData.abroad_start = abroad_start;
          // eslint-disable-next-line
          formData.abroad_end = abroad_end;
        }
        if (flowType) {
          formData.flowType = flowType;
        }
        if (sort) {
          formData.sort = sort;
        }
        if (countries && countries.length > 0) {
          formData.countries = countries;
        }
        console.log(formData);
        resolve(formData);
      });
      getFormData.then((formData) => {
        let api = '';
        if (this.$route.query.code) {
          setStorage('marketCode', this.$route.query.code);
          api += 'promotion.';
          if (getStorage('token')) {
            api += 'getMarketProductToken';
          } else {
            api += 'getMarketProduct';
          }
        } else {
          api += 'product.';
          if (getStorage('token')) {
            api += 'searchResultToken';
          } else {
            api += 'searchResult';
          }
        }
        let getResult;
        if (this.$route.query.code) {
          getResult = requestApi(api, { ...formData, code: this.$route.query.code });
        } else {
          getResult = requestApi(api, formData);
        }
        getResult.then((result) => {
          console.log(result);
          console.log(parseInt(this.$route.query.productCategory, 10));
          if (result.status) {
            if (parseInt(this.$route.query.productCategory, 10) === 0) {
              this.getCategoryType(result.data);
            } else if (Number.isNaN(parseInt(this.$route.query.productCategory, 10))) {
              this.getCategoryType(result.data);
            } else {
              this.itemsArray = result.data;
            }
            // this.productSetsData = arr;
          } else if (result.message === '無任何資料') {
            this.itemsArray = [];
          } else {
            this.itemsArray = [];
            this.$customSWAL({
              icon: 'error',
              title: '錯誤',
              text: result.message,
              confirmButtonText: '確定',
            });
          }
          console.log(result.data);
        });
      });
    },
  },
  computed: {
    ...mapState([
      'user',
    ]),
    getCategoryName() {
      const { productCategory } = this.$route.query;
      let name;
      if (parseInt(productCategory, 10) === 1) {
        name = 'WIFI分享器';
      } else if (parseInt(productCategory, 10) === 2) {
        name = 'SIM卡';
      } else if (parseInt(productCategory, 10) === 3) {
        name = 'eSIM';
      } else {
        name = productCategory;
      }
      return name;
    },
  },
  mounted() {
    this.getProductSetsData();
    this.getData();
  },
  watch: {
    $route() {
      this.getProductSetsData();
      this.getData();
    },
  },
};
</script>
